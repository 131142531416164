import React, {useEffect, useState} from 'react';
import RenderHtml from 'react-native-render-html';
import {useInnerStyle} from './article.hooks';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {goBack, scaleSize} from '@utils';
import {ArticleListItem, getArticleDetailService} from './article.service';
import {ScrollView, View, Image} from 'react-native'; //Platform
import theme from '@/style';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import {LazyImageLGBackground} from '@/components/basic/image';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';
// import RNConfig from 'react-native-config';

const ArticleDetail = () => {
  const {i18n} = useTranslation();
  const {id} = useRoute().params as BasicObject;

  const [item, setItem] = useState<ArticleListItem>();
  const {
    size: {screenHeight, screenWidth},
    listStyle,
    // detailStyle,
  } = useInnerStyle();

  const getArticleDetail = async () => {
    try {
      const res = await getArticleDetailService(id as number);
      setItem(res);
    } catch (error) {}
  };

  useEffect(() => {
    getArticleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const source = {
    html: item?.articleContent || '',
  };
  const htmlStyle = {
    fontSize: theme.fontSize.m,
    lineHeight: theme.fontSize.m * 1.4,
    fontFamily: 'Inter',
    color: '000000',
  };
  const tagsStyles = {
    p: {
      marginTop: 0,
    },
    img: {
      width: screenWidth - (theme.paddingSize.xxl + theme.paddingSize.l) * 2,
    },
  };

  // const IS_WEB = Platform.OS === 'web';

  // const ENV_CONFIG = (IS_WEB ? process.env : RNConfig) as {
  //   REACT_APP_ENV: 'dev' | 'prod';
  //   REACT_APP_API_BASE_URL: string;
  //   REACT_APP_API_INDUSWIN_URL?: string;
  //   REACT_APP_API_SPORTS_URL?: string;
  //   REACT_APP_API_H5GAMES_URL?: string;
  //   REACT_APP_API_RACECAR_URL?: string;
  //   REACT_APP_API_H5VUE_URL?: string;
  //   [k: string]: string | number | undefined;
  // };

  return (
    <LazyImageLGBackground style={[{height: screenHeight}]}>
      <DetailNavTitle
        title={i18n.t('Article')}
        hideAmount
        hideServer
        onBack={goBack}
      />
      <ScrollView
        style={[theme.flex.flex1, theme.background.white]}
        contentContainerStyle={[listStyle.list]}>
        <Image
          style={[
            {
              width: screenWidth,
              height: scaleSize(120),
            },
          ]}
          source={{
            uri: item?.articleImg,
          }}
        />
        <Text
          fontSize={20}
          fontWeight="700"
          style={[
            {
              color: '#000000',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 20,
            },
          ]}>
          {item?.articleTitle}
        </Text>
        <View style={[theme.background.white, theme.padding.xxl]}>
          <RenderHtml
            source={source}
            baseStyle={htmlStyle}
            tagsStyles={tagsStyles}
          />
        </View>
      </ScrollView>
    </LazyImageLGBackground>
  );
};

export default ArticleDetail;
